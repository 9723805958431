<template>
  <v-card :loading="loading" flat>
    <v-container>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-text-field v-model="screen.title" label="Titel" />
            </v-col>
            <v-col>
              <v-text-field v-model="screen.image" label="Bild" />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <DateInput v-model="screen.startDate" label="Startdatum"
            /></v-col>
            <v-col
              ><TimeInput v-model="screen.startTime" label="Startzeit"
            /></v-col>
            <v-col
              ><DateInput v-model="screen.endDate" label="Enddatum"
            /></v-col>
            <v-col
              ><TimeInput v-model="screen.endTime" label="Endzeit"
            /></v-col>
          </v-row>

          <v-toolbar outlined flat class="mb-3">
            <v-btn
              class="mr-1"
              outlined
              color="primary"
              @click="inputSurround('\n# ', '\n')"
              ><v-icon>mdi-format-header-1</v-icon></v-btn
            >
            <v-btn
              class="mr-4"
              outlined
              color="primary"
              @click="inputSurround('\n## ', '\n')"
              ><v-icon>mdi-format-header-2</v-icon></v-btn
            >
            <v-btn
              class="mr-1"
              outlined
              color="primary"
              @click="inputSurround('**', '** ')"
              ><v-icon>mdi-format-bold</v-icon></v-btn
            >

            <v-btn
              outlined
              color="primary"
              class="mr-4"
              @click="inputSurround('*', '* ')"
              ><v-icon>mdi-format-italic</v-icon></v-btn
            >

            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  color="primary"
                  @click="showDialog"
                  :loading="loadingImages"
                  ><v-icon>mdi-image</v-icon></v-btn
                >
              </template>
              <span>Bilder hinzufügen/bearbeiten</span>
            </v-tooltip>
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteTime"
                  outlined
                  color="primary"
                  ><v-icon>mdi-calendar-minus</v-icon></v-btn
                >
              </template>
              <span>Dauer entfernen</span>
            </v-tooltip>

            <v-dialog v-model="dialog" max-width="500" width="100%" scrollable>
              <v-card>
                <v-system-bar> Bilder</v-system-bar>
                <v-banner single-line>
                  <form ref="imageForm">
                    <v-file-input
                      dense
                      single-line
                      accept="image/*"
                      name="file"
                      prepend-icon="mdi-image"
                      label="Bilddatei raufladen"
                      append-outer-icon="mdi-upload"
                      @click:append-outer="upload()"
                    >
                    </v-file-input>
                  </form>
                </v-banner>
                <v-list>
                  <v-list-group
                    v-for="item in images"
                    :key="item.name"
                    no-action
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        {{ item.name }}
                      </v-list-item-content>
                    </template>
                    <v-card flat>
                      <v-img
                        class="mx-4"
                        :src="baseUrl + 'screen/image/' + item.name"
                      ></v-img>

                      <v-card-actions>
                        <v-btn
                          text
                          color="error"
                          outlined
                          @click="deleteImage(item.name)"
                          >löschen</v-btn
                        >
                        <v-spacer />
                        <v-btn
                          text
                          color="info"
                          outlined
                          @click="insertImage(item.name)"
                          >einfügen</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          outlined
                          @click="setImage(item.name)"
                          >setzen</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-list-group>
                </v-list>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    Schliessen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>

          <v-textarea
            outlined
            v-model="screen.content"
            label="Inhalt"
            rows="10"
            ref="content"
          />
        </v-col>
        <v-col>
          <v-alert
            v-if="screen.active"
            :icon="
              screen.startDate || screen.endDate
                ? 'mdi-calendar-clock'
                : 'mdi-calendar'
            "
            border="left"
            :color="screen.current ? 'primary' : 'grey'"
            text
          >
            <span v-if="screen.current">
              <span v-if="screen.endDate"
                >Läuf noch bis am <DateValue long :value="screen.endDate" /> um
                {{ screen.endTime }} Uhr</span
              >
              <span v-else>Wird momentan angezeigt.</span>
            </span>
            <span v-if="screen.future">
              Startet am
              <DateValue long :value="screen.startDate" /> um
              {{ screen.startTime }} Uhr
            </span>
            <span v-if="screen.past">
              Lief bis am
              <DateValue long :value="screen.endDate" /> um
              {{ screen.endTime }} Uhr
            </span>
          </v-alert>
          <div ref="containerScreen">
            <v-responsive
              :aspect-ratio="16 / 9"
              class="mb-4"
              v-resize="updateSize"
            >
              <div id="wrap">
                <iframe :style="style" id="frame" :src="previewUrl" />
              </div>
            </v-responsive>
          </div>
          <v-slider
            v-model="screen.fontSize"
            min="100"
            max="600"
            label="Schriftgrösse"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-divider />
    <v-card-actions>
      <v-btn
        :loading="deleting"
        :disabled="screen.id == 0"
        @click="trash"
        color="error"
        text
        >löschen</v-btn
      >
      <v-spacer />
      <v-btn :loading="saveing" @click="save" color="error" text
        >Speichern</v-btn
      ></v-card-actions
    >
  </v-card>
</template>
<script>
import DateValue from "common/components/DateValue.vue";
import DateInput from "common/components/DateInput.vue";
import TimeInput from "common/components/TimeInput.vue";

export default {
  name: "ScreenScreen",
  components: { DateValue, DateInput, TimeInput },
  props: ["id"],
  data() {
    return {
      baseUrl: "",
      deleting: false,
      loading: false,
      loadingImages: false,
      dialog: false,
      saveing: false,
      screen: {
        id: 0,
        content: "# Überschrift\nText",
        fontSize: 200,
        image: null,
      },
      images: [],
      scale: 0.1,
    };
  },

  computed: {
    style() {
      return { transform: "scale(" + this.scale + ")" };
    },

    previewUrl() {
      var base = "https://screen.gymkirchenfeld.ch/";
      if (!this.apiIsProd()) {
        base = "https://screen2.gymkirchenfeld.ch/";
      }
      return `${base}?preview=true&fontSize=${this.screen.fontSize}&content=${
        this.screen.content ? encodeURIComponent(this.screen.content) : ""
      }${this.screen.image ? "&image=" + this.screen.image : ""}`;
    },
  },
  watch: {
    id() {
      this.getData();
    },
  },
  methods: {
    deleteTime() {
      this.screen.startDate = null;
      this.screen.startTime = null;
      this.screen.endDate = null;
      this.screen.endTime = null;
    },
    async getData() {
      this.baseUrl = this.apiBaseUrl();
      this.screen = {
        id: 0,
        content: "# Überschrift\nText",
        fontSize: 200,
      };
      if (this.id > 0) {
        this.loading = true;
        this.screen = await this.apiGet({
          resource: "screen/screen",
          id: this.id,
        });
        this.loading = false;
      }
    },
    inputSurround(before, after) {
      const input = this.$refs.content.$refs.input;

      // save selection start and end position
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // update the value with our text inserted
      this.screen.content =
        this.screen.content.slice(0, start) +
        before +
        this.screen.content.slice(start, end) +
        after +
        this.screen.content.slice(end);

      // timeout need because we change vue-variable and not textarea-content
      window.setTimeout(() => {
        if (start == end) {
          input.selectionStart = input.selectionEnd = start + before.length;
        } else {
          input.selectionStart = input.selectionEnd =
            end + before.length + after.length;
        }
        input.focus();
      }, 100);
    },
    insertImage(imageName) {
      this.dialog = false;
      this.inputSurround("![", "](/api/screen/image/" + imageName + ")");
    },
    setImage(imageName) {
      this.dialog = false;
      this.screen.image = imageName;
    },
    async upload() {
      var formElement = this.$refs.imageForm;
      await this.apiUploadPost({ resource: "screen/image", form: formElement });
      this.loadImages();
    },
    async save() {
      this.saveing = true;
      if (this.id > 0) {
        await this.apiPut({ resource: "screen/screen", data: this.screen });
      } else {
        this.screen = await this.apiPost({
          resource: "screen/screen",
          data: this.screen,
        });
        this.$router.push({
          name: "ScreenScreen",
          params: { id: this.screen.id },
        });
      }
      this.$root.showSuccess("erfolgreich gespeichert");
      this.$emit("updated");
      this.saveing = false;
    },
    async deleteImage(imageName) {
      if (
        await this.$root.confirm({
          message: "bist du sicher?",
          color: "primary",
          icon: "mdi-information",
        })
      ) {
        await this.apiDelete({ resource: "screen/image", id: imageName });
        this.$root.showSuccess("erfolgreich gelöscht");
        this.loadImages();
      }
    },
    async trash() {
      if (
        await this.$root.confirm({
          message: "bist du sicher?",
          color: "primary",
          icon: "mdi-information",
        })
      ) {
        this.deleting = true;
        await this.apiDelete({ resource: "screen/screen", id: this.screen.id });
        this.$root.showSuccess("erfolgreich gelöscht");
        this.$emit("updated");
        this.deleting = false;
        this.$router.push({ name: "ScreenScreens" });
      }
    },
    async loadImages() {
      this.imagesLoading = true;
      this.images = await this.apiList({ resource: "screen/image" });
      this.loadingImages = false;
    },
    async showDialog() {
      await this.loadImages();
      this.dialog = true;
    },
    updateSize() {
      var computed = getComputedStyle(this.$refs.containerScreen);
      const width =
        this.$refs.containerScreen.offsetWidth -
        parseInt(computed.paddingLeft) -
        parseInt(computed.paddingRight);
      this.scale = width / 1920;
    },
  },

  async created() {
    await this.getData();
  },
  updated() {
    this.updateSize();
  },
};
</script>

<style scoped>
#wrap {
  overflow: hidden;
  position: absolute;
}
#frame {
  width: 1920px;
  height: 1080px;
  border: 3px solid black;
}
#frame {
  transform: scale(0.1);
  transform-origin: left top;
}
</style>
